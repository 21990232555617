import React, { useEffect, useState } from 'react';
import { NavLink, Link } from "react-router-dom";
import * as GlobalProvider from '../../providers/globals/globals';
import Header from '../../components/elements/Header';
import Pagenation from '../../components/elements/Pagenation';
import * as ApisService from "../../providers/apis/apis";
import { Roller } from "react-awesome-spinners";
import SideMenuData from '../../components/elements/SideMenu';
import * as CustomValidators from '../../providers/shared/validator';

function UserEdit(props) {

    const [id] = useState(props.match.params.id);
    const [currentUser, setCurrentUser] = useState({});
    const [formData, setFormData] = useState({});
    const [status, setStatus] = useState(null);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);

    useEffect(() => {

        loadData(id);
    }, []);

    const handleStatusChange = (event) => {

        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;

        formData[name] = value;
        
        setStatus(value);
        setFormData(formData);
    }

    const handleChange = (event) => {

        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;

        formData[name] = value;

        setFormData(formData);
    }

    const loadData = (id) => {

        setErrors({});
        setLoading(false);

        ApisService.getUserDetail(id)
            .then(response => {

                if (response.status) {
                    setLoading(false);
                    setFormData(response.data);
                    setStatus(response.data.status);
                } else {
                    GlobalProvider.errorMessage(response.message);
                }

            }).catch(error => {
                GlobalProvider.errorMessage(error);
            });
    }

    const validateLoginForm = (e) => {

        let errors = {};

        if (CustomValidators.isEmpty(formData.name)) {
            errors.name = "First Name can't be blank";
        }

        if (CustomValidators.isEmpty(errors)) {
            return null;
        } else {
            return errors;
        }
    }

    const addEdit = (e) => {

        e.preventDefault();

        setErrors({});
        setLoading(true);
        setFormSubmitted(true);

        let errors = validateLoginForm();

        if (!errors) {

            ApisService.updateUser(formData)
                .then(response => {

                    if (response.status) {

                        GlobalProvider.successMessage(response.message);

                        setLoading(false);
                        setFormSubmitted(false);
                        props.history.push('/users');
                    } else {
                        setLoading(false);
                        setFormSubmitted(false);
                        GlobalProvider.errorMessage(response.message);
                    }

                }).catch(error => {
                    setLoading(false);
                    setFormSubmitted(false);
                    GlobalProvider.errorMessage(error);
                });

        } else {
            setErrors(errors);
            setLoading(false);
            setFormSubmitted(false);
        }
    }

    return (
        <>
            <SideMenuData />

            <main className="admin-main">

                <Header />

                <section className="admin-content ">
                    <div className="bg-dark bg-dots m-b-30">
                        <div className="container">
                            <div className="row p-b-60 p-t-60">
                                <div className="col-lg-8 mx-auto text-white p-b-30">
                                    <h3>Update Form </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="pull-up">
                        <div className="container">
                            <div className="row ">
                                <div className="col-lg-8 mx-auto  mt-2">
                                    <div className="card py-3 m-b-30">
                                        <div className="card-body">

                                            <form className="needs-validation" onSubmit={addEdit} method="true">

                                                <h3 className="">Update Information</h3>

                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label for="inputEmail6">First Name*</label>
                                                        <input type="text" className="form-control" name="name" defaultValue={formData.name} onKeyUp={handleChange} />
                                                        {errors.name && <span className="error">{errors.name}</span>}
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label for="inputEmail6">Last Name</label>
                                                        <input type="text" className="form-control" name="last_name" defaultValue={formData.last_name} onKeyUp={handleChange} />
                                                        {errors.last_name && <span className="error">{errors.last_name}</span>}
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label>Email</label>
                                                        <input type="text" value={formData.email} className="form-control" readOnly />
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label>DOB</label>
                                                        <input type="text" value={formData.dob} className="form-control" readOnly />
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label>Mobile</label>
                                                        <input type="text" value={formData.mobile} className="form-control" readOnly />
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label for="inputEmail4">Status </label>
                                                        <select className="form-control" name="status" value={status} onChange={handleStatusChange} >
                                                            <option value={1}>Approved</option>
                                                            <option value={0}>Un-Approved</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <button type="submit" className="btn btn-primary btn-cta" disabled={loading}>{loading ? 'Waiting...' : 'Submit'}</button>
                                                    &nbsp;&nbsp;
                                                <Link to={"/users"} className="btn btn-dark btn-cta">Cancel</Link>

                                            </form>

                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </section>
                </section>
            </main>

        </>
    );
}

export default UserEdit;