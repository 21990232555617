import React, { useEffect, useState } from 'react';
import { NavLink, Link } from "react-router-dom";
import * as GlobalProvider from '../../providers/globals/globals';
import Header from '../../components/elements/Header';
import Pagenation from '../../components/elements/Pagenation';
import * as ApisService from "../../providers/apis/apis";
import { Roller } from "react-awesome-spinners";
import SideMenuData from '../../components/elements/SideMenu';
import * as CustomValidators from '../../providers/shared/validator';

function HomeSettings(props) {

    const [currentUser, setCurrentUser] = useState({});
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);

    useEffect(() => {

        loadData();
    }, []);

    const handleChange = (event) => {

        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;

        formData[name] = value;

        setFormData(formData);
    }

    const loadData = () => {

        setErrors({});
        setLoading(false);

        ApisService.getHomePageSettings()
            .then(response => {

                if (response.status) {
                    setLoading(false);
                    setFormData(response.data);
                } else {
                    GlobalProvider.errorMessage(response.message);
                }

            }).catch(error => {
                GlobalProvider.errorMessage(error);
            });
    }

    const validateLoginForm = (e) => {

        let errors = {};

        // if (CustomValidators.isEmpty(formData.footer_text)) {
        //     errors.footer_text = "Footer Text can't be blank";
        // }

        if (CustomValidators.isEmpty(errors)) {
            return null;
        } else {
            return errors;
        }
    }

    const addEdit = (e) => {

        e.preventDefault();

        setErrors({});
        setLoading(true);
        setFormSubmitted(true);

        let errors = validateLoginForm();

        if (!errors) {

            ApisService.updateHomePageSettings(formData)
                .then(response => {

                    if (response.status) {

                        GlobalProvider.successMessage(response.message);

                        setLoading(false);
                        setFormSubmitted(false);
                        props.history.push('/dashboard');
                    } else {
                        setLoading(false);
                        setFormSubmitted(false);
                        GlobalProvider.errorMessage(response.message);
                    }

                }).catch(error => {
                    setLoading(false);
                    setFormSubmitted(false);
                    GlobalProvider.errorMessage(error);
                });

        } else {
            setErrors(errors);
            setLoading(false);
            setFormSubmitted(false);
        }
    }

    return (
        <>
            <SideMenuData />

            <main className="admin-main">

                <Header />

                <section className="admin-content ">
                    <div className="bg-dark bg-dots m-b-30">
                        <div className="container">
                            <div className="row p-b-60 p-t-60">
                                <div className="col-lg-8 mx-auto text-white p-b-30">
                                    <h3>Home Page Settings </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="pull-up">
                        <div className="container">
                            <div className="row ">
                                <div className="col-lg-8 mx-auto  mt-2">
                                    <div className="card py-3 m-b-30">
                                        <div className="card-body">

                                            <form className="needs-validation" onSubmit={addEdit} method="true">

                                                <div className="form-row">
                                                    <div className="form-group col-md-12">
                                                        <label for="inputEmail4">Banner Text Line 1</label>
                                                        <input type="text" className="form-control" name="banner_text_1" defaultValue={formData.banner_text_1} onKeyUp={handleChange} />
                                                        {errors.banner_text_1 && <span className="error">{errors.banner_text_1}</span>}
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="form-group col-md-12">
                                                        <label for="inputEmail4">Banner Text Line 2</label>
                                                        <input type="text" className="form-control" name="banner_text_2" defaultValue={formData.banner_text_2} onKeyUp={handleChange} />
                                                        {errors.banner_text_2 && <span className="error">{errors.banner_text_2}</span>}
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="form-group col-md-12">
                                                        <label for="inputEmail4">Banner Text Line 2 (red text)</label>
                                                        <input type="text" className="form-control" name="banner_text_2_red" defaultValue={formData.banner_text_2_red} onKeyUp={handleChange} />
                                                        {errors.banner_text_2_red && <span className="error">{errors.banner_text_2_red}</span>}
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="form-group col-md-12">
                                                        <label for="inputEmail4">Choose Program Text</label>
                                                        <input type="text" className="form-control" name="choose_program_text" defaultValue={formData.choose_program_text} onKeyUp={handleChange} />
                                                        {errors.choose_program_text && <span className="error">{errors.choose_program_text}</span>}
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="form-group col-md-12">
                                                        <label for="inputEmail4">Body Line Works Text</label>
                                                        <input type="text" className="form-control" name="body_line_works_text" defaultValue={formData.body_line_works_text} onKeyUp={handleChange} />
                                                        {errors.body_line_works_text && <span className="error">{errors.body_line_works_text}</span>}
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="form-group col-md-12">
                                                        <label for="inputEmail4">Weight Loss Text</label>
                                                        <input type="text" className="form-control" name="weight_loss_text" defaultValue={formData.weight_loss_text} onKeyUp={handleChange} />
                                                        {errors.weight_loss_text && <span className="error">{errors.weight_loss_text}</span>}
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="form-group col-md-12">
                                                        <label for="inputEmail4">Success Stories Text</label>
                                                        <input type="text" className="form-control" name="success_stories_text" defaultValue={formData.success_stories_text} onKeyUp={handleChange} />
                                                        {errors.success_stories_text && <span className="error">{errors.success_stories_text}</span>}
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="form-group col-md-12">
                                                        <label for="inputEmail4">BMI Text</label>
                                                        <input type="text" className="form-control" name="bmi_text" defaultValue={formData.bmi_text} onKeyUp={handleChange} />
                                                        {errors.bmi_text && <span className="error">{errors.bmi_text}</span>}
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="form-group col-md-12">
                                                        <label for="inputEmail4">Map Text</label>
                                                        <input type="text" className="form-control" name="map_text" defaultValue={formData.map_text} onKeyUp={handleChange} />
                                                        {errors.map_text && <span className="error">{errors.map_text}</span>}
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="form-group col-md-12">
                                                        <label for="inputEmail4">Admin Notify Emails</label>
                                                        <input type="text" className="form-control" name="admin_emails" defaultValue={formData.admin_emails} onKeyUp={handleChange} />
                                                        <small>comma separated for ex: admin1@example.com, admin2@example.com</small>
                                                        {errors.admin_emails && <span className="error">{errors.admin_emails}</span>}
                                                    </div>
                                                </div>

                                                <button type="submit" className="btn btn-primary btn-cta" disabled={loading}>{loading ? 'Waiting...' : 'Submit'}</button>
                                                    &nbsp;&nbsp;
                                                <Link to={"/programs"} className="btn btn-dark btn-cta">Cancel</Link>

                                            </form>

                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </section>
                </section>
            </main>

        </>
    );
}

export default HomeSettings;