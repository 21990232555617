import React, { useEffect, useState } from 'react';
import { NavLink, Link } from "react-router-dom";
import * as GlobalProvider from '../../providers/globals/globals';
import Header from '../../components/elements/Header';
import Pagenation from '../../components/elements/Pagenation';
import RecordNotFound from '../../components/elements/RecordNotFound';
import * as ApisService from "../../providers/apis/apis";
import { Roller } from "react-awesome-spinners";
import SideMenu from '../../components/elements/SideMenu';

function Newsletters(props) {

    const [currentUser, setCurrentUser] = useState({});
    const [loading, setLoading] = useState(false);
    const [entries, setEntries] = useState([]);

    useEffect(() => {

        loadData();
    }, []);

    const loadData = () => {

        setLoading(true);

        ApisService.getNewsletters()
            .then(response => {

                if (response.status) {
                    setEntries(response.data);
                    setLoading(false);
                } else {
                    setLoading(false);
                    GlobalProvider.errorMessage(response.message);
                }

                GlobalProvider.loadDataTable();

            }).catch(error => {
                setLoading(false);
                GlobalProvider.errorMessage(error);
            });
    }

    const changeStatus = (id, status) => {

        GlobalProvider.confirmBox("Are you Sure? You want to change status of this record.", (isTrue) => {
            if (isTrue) {
                ApisService.changeNewsletterStatus(id, status)
                    .then(response => {

                        if (response.status) {

                            // update item status and set updated value into state

                            let entriesNew = entries.filter((item) => {
                                if (item.id == id) {
                                    item.status = status;
                                }

                                return item;
                            });

                            setEntries(entriesNew);

                            GlobalProvider.successMessage(response.message);

                        } else {
                            GlobalProvider.errorMessage(response.message);
                        }

                    }).catch(error => {
                        GlobalProvider.errorMessage(error.message);
                    });
            }
        });
    }

    return (
        <>
            <SideMenu />

            <main className="admin-main">

                <Header />

                <section className="admin-content">
                    <div className="bg-dark">
                        <div className="container  m-b-30">
                            <div className="row">
                                <div className="col-12 text-white p-t-40 p-b-90">
                                    <h4 className="">Newsletters</h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    {loading && <div className="center"><Roller /></div>}

                    {!loading && entries.length == 0 && <RecordNotFound />}

                    {entries.length > 0 &&

                        <div className="container  pull-up">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">

                                        <div className="card-body">
                                            <div className="table-responsive p-t-10">
                                                <table id="listingTable" className="table " style={{ width: "100%" }}>
                                                    <thead>
                                                        <tr>
                                                            <th>Email</th>
                                                            <th>Subscribed Date</th>
                                                            <th>Un-Subscribed Date</th>
                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {entries.map((item, index) =>
                                                            <tr key={item.id} id={'RecordID_' + item.id}>
                                                                <td>{item.email}</td>
                                                                <td>{GlobalProvider.formatedDate(item.created_at, 'D MMMM YYYY, h:mm a')}</td>
                                                                <td>{GlobalProvider.unSubscribedDate(item.updated_at, 'D MMMM YYYY, h:mm a', item.status)}</td>
                                                                <td>
                                                                    <span className="changeStatus" onClick={() => changeStatus(item.id, !item.status)}>
                                                                        {item.status ? (
                                                                            <button type="button" className="btn btn-sm m-b-15 ml-2 mr-2 btn-success" title="Disable">Subscribed <i className="mdi mdi-check"></i></button>
                                                                        ) : (
                                                                                <button type="button" className="btn btn-sm m-b-15 ml-2 mr-2 btn-warning" title="Enable">Un-Subscribed <i className="mdi mdi-close"></i></button>
                                                                            )}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        )}

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                </section>

            </main>
        </>
    );

}

export default Newsletters;