import React, { useEffect, useState } from 'react';
import { NavLink, Link } from "react-router-dom";
import * as GlobalProvider from '../../providers/globals/globals';
import Header from '../../components/elements/Header';
import Pagenation from '../../components/elements/Pagenation';
import * as ApisService from "../../providers/apis/apis";
import { Roller } from "react-awesome-spinners";
import SideMenuData from '../../components/elements/SideMenu';
import * as CustomValidators from '../../providers/shared/validator';

function BodylineAdd(props) {

    const [currentUser, setCurrentUser] = useState({});
    const [formData, setFormData] = useState({ status: 1 });
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedIcon, setSelectedIcon] = useState(null);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);

    const onIconSelection = event => {

        setSelectedIcon(event.target.files[0]);
    };

    const onFileSelection = event => {

        setSelectedFile(event.target.files[0]);
    };

    const handleChange = (event) => {
        
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;

        formData[name] = value;

        setFormData(formData);
    }

    const validateLoginForm = (e) => {

        let errors = {};

        if (CustomValidators.isEmpty(formData.title)) {
            errors.title = "Title can't be blank";
        }

        if (CustomValidators.isEmpty(formData.message)) {
            errors.message = "Message can't be blank";
        }

        if (CustomValidators.isEmpty(errors)) {
            return null;
        } else {
            return errors;
        }
    }

    const addEdit = (e) => {

        e.preventDefault();

        setErrors({});
        setLoading(true);
        setFormSubmitted(true);

        let errors = validateLoginForm();

        if (!errors) {

            // append all information into form
            let formDataWithImage = new FormData();
            for (let [key, value] of Object.entries(formData)) {

                if(!['icon', 'image'].includes(key)){
                    formDataWithImage.append(key, value);
                }
            }
            formDataWithImage.append("icon", selectedIcon);
            formDataWithImage.append("image", selectedFile);

            ApisService.createBodyline(formDataWithImage)
                .then(response => {

                    if (response.status) {

                        GlobalProvider.successMessage(response.message);

                        setLoading(false);
                        setFormSubmitted(false);
                        props.history.push('/bodylines');
                    } else {
                        setLoading(false);
                        setFormSubmitted(false);
                        GlobalProvider.errorMessage(response.message);
                    }

                }).catch(error => {
                    setLoading(false);
                    setFormSubmitted(false);
                    GlobalProvider.errorMessage(error);
                });

        } else {
            setErrors(errors);
            setLoading(false);
            setFormSubmitted(false);
        }
    }

    return (
        <>
            <SideMenuData />

            <main className="admin-main">

                <Header />

                <section className="admin-content ">
                    <div className="bg-dark bg-dots m-b-30">
                        <div className="container">
                            <div className="row p-b-60 p-t-60">
                                <div className="col-lg-8 mx-auto text-white p-b-30">
                                    <h3>Add Form </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="pull-up">
                        <div className="container">
                            <div className="row ">
                                <div className="col-lg-8 mx-auto  mt-2">
                                    <div className="card py-3 m-b-30">
                                        <div className="card-body">

                                            <form className="needs-validation" onSubmit={addEdit} method="true">

                                                <h3 className="">Add Information</h3>

                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label for="inputEmail4">Title*</label>
                                                        <input type="text" className="form-control" name="title" defaultValue={formData.title} onKeyUp={handleChange} />
                                                        {errors.title && <span className="error">{errors.title}</span>}
                                                    </div>
                                                    <div className="form-group col-md-6 file">
                                                        <div className="form-group col-md-6">
                                                            <label for="inputEmail4">Icon*</label>
                                                            <input type="file" className="form-control" name="icon" onChange={onIconSelection} required />
                                                            {errors.icon && <span className="error">{errors.icon}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-md-12">
                                                        <label>Message*</label>
                                                        <textarea rows="10" name="message" defaultValue={formData.message} className="form-control" onKeyUp={handleChange} />
                                                        {errors.message && <span className="error">{errors.message}</span>}
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label for="inputEmail4">Status</label>
                                                        <select className="form-control" name="status" onChange={handleChange} >
                                                            <option value={1}>Active</option>
                                                            <option value={0}>In-Active</option>
                                                        </select>
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <div className="form-group col-md-6">
                                                            <label for="inputEmail4">Image</label>
                                                            <input type="file" className="form-control" name="image" onChange={onFileSelection} required />
                                                            {errors.image && <span className="error">{errors.image}</span>}
                                                        </div>
                                                    </div>
                                                </div>

                                                <button type="submit" className="btn btn-primary btn-cta" disabled={loading}>{loading ? 'Waiting...' : 'Submit'}</button>
                                                    &nbsp;&nbsp;
                                                <Link to={"/bodylines"} className="btn btn-dark btn-cta">Cancel</Link>

                                            </form>

                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </section>
                </section>
            </main>

        </>
    );
}

export default BodylineAdd;