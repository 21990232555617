import React from 'react';

function RecordNotFound(props) {

    return (
        <div className="container  pull-up">
            <div className="row">
                <div className="col-12">
                    <div className="card">

                        <div className="card-body">
                            <div className="table-responsive p-t-10">
                                <table id="listingTable" className="table" style={{ width: "100%" }}>
                                    <tbody>
                                        <tr>
                                            <td>Record not found</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RecordNotFound;