import axios from 'axios';
import * as GlobalProvider from '../globals/globals';
import * as GlobalConfig from '../globals/config';
import { createReadStream } from 'fs';

axios.defaults.baseURL = GlobalConfig.baseURL;
axios.defaults.headers.post['Content-Type'] = 'application/json';

function updateHeaders() {
    let token = GlobalProvider.getToken();
    if (token) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
    }
}

function readError(error) {
    // console.log('error:::', error)

    let message;
    let errorMsg = error;

    if (typeof errorMsg === 'undefined') {
        message = "Something went wrong, Please try again.";
    }
    else if (typeof errorMsg === 'object') {

        if (typeof errorMsg.message !== 'undefined') {
            message = errorMsg.message;
        }
        else if (typeof errorMsg.error !== 'undefined') {
            message = errorMsg.error;
        }
        else {
            message = "Something went wrong, please try again.";
        }
    }
    else {
        message = errorMsg;
    }

    return message;
}

function readResponse(response) {
    // console.log('response::::', response.data);
    return response.data;
}

// Dashboard 
export const getDashboard = () => {
    updateHeaders();
    return axios.get(`/dashboard`).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

// Login 
export const login = (params) => {
    return axios.post(`/login`, params).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

// Settings 
export const getAccountDetail = (id) => {
    updateHeaders();
    return axios.get(`/settings/detail-account/${id}`).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const updateAccount = (params) => {
    updateHeaders();
    return axios.post(`/settings/update-account`, params).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const changePassword = (params) => {
    updateHeaders();
    return axios.post(`/settings/change-password`, params).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const getHomePageSettings = () => {
    updateHeaders();
    return axios.get(`/settings/get-home-page-settings`).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const updateHomePageSettings = (params) => {
    updateHeaders();
    return axios.post(`/settings/update-home-page-settings`, params).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

// users
export const getUsers = () => {
    updateHeaders();
    return axios.get(`/users/list`).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const updateUser = (params) => {
    updateHeaders();
    return axios.post(`/users/update`, params).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const getUserDetail = (id) => {
    updateHeaders();
    return axios.get(`/users/view/${id}`).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const changeUserStatus = (id, status) => {
    updateHeaders();
    return axios.put(`/users/change-status/${id}/${status}`).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const deleteUser = (id) => {
    updateHeaders();
    return axios.put(`/users/delete/${id}`).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const getArchiveUsers = (role) => {
    updateHeaders();
    return axios.get(`/users/archive-list`).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const restoreUser = (id) => {
    updateHeaders();
    return axios.put(`/users/restore/${id}`).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

// stories
export const getStories = () => {
    updateHeaders();
    return axios.get(`/stories/list`).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const getArchiveStories = (role) => {
    updateHeaders();
    return axios.get(`/stories/archive-list`).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const createStory = (params) => {
    updateHeaders();
    return axios.post(`/stories/create`, params).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const updateStory = (params) => {
    updateHeaders();
    return axios.post(`/stories/update`, params).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const getStoryDetail = (id) => {
    updateHeaders();
    return axios.get(`/stories/view/${id}`).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const deleteStory = (id) => {
    updateHeaders();
    return axios.put(`/stories/delete/${id}`).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const restoreStory = (id) => {
    updateHeaders();
    return axios.put(`/stories/restore/${id}`).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const changeStoryStatus = (id, status) => {
    updateHeaders();
    return axios.put(`/stories/change-status/${id}/${status}`).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

// body-line
export const getBodylines = () => {
    updateHeaders();
    return axios.get(`/body-lines/list`).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const getArchiveBodylines = (role) => {
    updateHeaders();
    return axios.get(`/body-lines/archive-list`).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const createBodyline = (params) => {
    updateHeaders();
    return axios.post(`/body-lines/create`, params).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const updateBodyline = (params) => {
    updateHeaders();
    return axios.post(`/body-lines/update`, params).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const getBodylineDetail = (id) => {
    updateHeaders();
    return axios.get(`/body-lines/view/${id}`).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const deleteBodyline = (id) => {
    updateHeaders();
    return axios.put(`/body-lines/delete/${id}`).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const restoreBodyline = (id) => {
    updateHeaders();
    return axios.put(`/body-lines/restore/${id}`).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const changeBodylineStatus = (id, status) => {
    updateHeaders();
    return axios.put(`/body-lines/change-status/${id}/${status}`).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

// choose programs
export const getPrograms = () => {
    updateHeaders();
    return axios.get(`/choose-programs/list`).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const getArchivePrograms = (role) => {
    updateHeaders();
    return axios.get(`/choose-programs/archive-list`).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const createProgram = (params) => {
    updateHeaders();
    return axios.post(`/choose-programs/create`, params).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const updateProgram = (params) => {
    updateHeaders();
    return axios.post(`/choose-programs/update`, params).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const getProgramDetail = (id) => {
    updateHeaders();
    return axios.get(`/choose-programs/view/${id}`).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const deleteProgram = (id) => {
    updateHeaders();
    return axios.put(`/choose-programs/delete/${id}`).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const restoreProgram = (id) => {
    updateHeaders();
    return axios.put(`/choose-programs/restore/${id}`).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const changeProgramStatus = (id, status) => {
    updateHeaders();
    return axios.put(`/choose-programs/change-status/${id}/${status}`).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

// faqs
export const getFaqs = () => {
    updateHeaders();
    return axios.get(`/faqs/list`).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const getArchiveFaqs = (role) => {
    updateHeaders();
    return axios.get(`/faqs/archive-list`).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const createFaq = (params) => {
    updateHeaders();
    return axios.post(`/faqs/create`, params).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const updateFaq = (params) => {
    updateHeaders();
    return axios.post(`/faqs/update`, params).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const getFaqDetail = (id) => {
    updateHeaders();
    return axios.get(`/faqs/view/${id}`).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const deleteFaq = (id) => {
    updateHeaders();
    return axios.put(`/faqs/delete/${id}`).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const restoreFaq = (id) => {
    updateHeaders();
    return axios.put(`/faqs/restore/${id}`).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const changeFaqStatus = (id, status) => {
    updateHeaders();
    return axios.put(`/faqs/change-status/${id}/${status}`).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

// pages
export const getPages = () => {
    updateHeaders();
    return axios.get(`/pages/list`).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const getArchivePages = (role) => {
    updateHeaders();
    return axios.get(`/pages/archive-list`).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const createPage = (params) => {
    updateHeaders();
    return axios.post(`/pages/create`, params).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const updatePage = (params) => {
    updateHeaders();
    return axios.post(`/pages/update`, params).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const getPageDetail = (id) => {
    updateHeaders();
    return axios.get(`/pages/view/${id}`).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const deletePage = (id) => {
    updateHeaders();
    return axios.put(`/pages/delete/${id}`).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const restorePage = (id) => {
    updateHeaders();
    return axios.put(`/pages/restore/${id}`).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const changePageStatus = (id, status) => {
    updateHeaders();
    return axios.put(`/pages/change-status/${id}/${status}`).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

// contacts
export const getContacts = () => {
    updateHeaders();
    return axios.get(`/contact-us/list`).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const getContactDetail = (id) => {
    updateHeaders();
    return axios.get(`/contact-us/view/${id}`).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

// newsletters
export const getNewsletters = () => {
    updateHeaders();
    return axios.get(`/newsletters/list`).then(response => readResponse(response)).catch(error => { throw readError(error); });
}

export const changeNewsletterStatus = (id, status) => {
    updateHeaders();
    return axios.put(`/newsletters/change-status/${id}/${status}`).then(response => readResponse(response)).catch(error => { throw readError(error); });
}
