import React, { useEffect, useState } from 'react';
import { NavLink, Link } from "react-router-dom";
import * as GlobalProvider from '../../providers/globals/globals';
import Header from '../../components/elements/Header';
import Pagenation from '../../components/elements/Pagenation';
import * as ApisService from "../../providers/apis/apis";
import { Roller } from "react-awesome-spinners";
import SideMenuData from '../../components/elements/SideMenu';
import * as CustomValidators from '../../providers/shared/validator';

function EditProfile(props) {

    const [currentUser, setCurrentUser] = useState(GlobalProvider.getUser());
    const [formData, setFormData] = useState({ user_id: currentUser.id });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);

    useEffect(() => {

        console.log('GlobalProvider.getUser()::', GlobalProvider.getUser())
        console.log('currentUser::', currentUser)
        // setCurrentUser(GlobalProvider.getUser());

        getAccountDetail(currentUser.id);
    }, []);

    const handleChange = (event) => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;

        formData[name] = value;

        setFormData(formData);
    }

    const getAccountDetail = (id) => {

        setErrors({});
        setLoading(false);

        ApisService.getAccountDetail(id)
            .then(response => {

                if (response.status) {
                    setLoading(false);
                    setFormData(response.data);
                } else {
                    GlobalProvider.errorMessage(response.message);
                }

            }).catch(error => {
                GlobalProvider.errorMessage(error);
            });
    }

    const validateLoginForm = (e) => {

        let errors = {};

        if (CustomValidators.isEmpty(formData.name)) {
            errors.name = "Name can't be blank";
        }

        if (!CustomValidators.isEmpty(formData.email) && !CustomValidators.isEmail(formData.email)) {
            errors.email = "Please enter a valid email";
        }

        if (!CustomValidators.isEmpty(formData.mobile) && !CustomValidators.isPhoneNumber(formData.mobile)) {
            errors.mobile = "Please inter valid mobile number.";
        }

        if (CustomValidators.isEmpty(errors)) {
            return null;
        } else {
            return errors;
        }
    }

    const update = (e) => {

        e.preventDefault();

        setErrors({});
        setLoading(true);
        setFormSubmitted(true);

        let errors = validateLoginForm();

        if (!errors) {

            ApisService.updateAccount(formData)
                .then(response => {

                    if (response.status) {

                        let userInfo = currentUser;
                        userInfo.name = formData.name;
                        userInfo.email = formData.email;
                        userInfo.mobile = formData.mobile;
                        userInfo.address = formData.address;
                        console.log('userInfo:', userInfo)

                        GlobalProvider.setUser(userInfo);
                        GlobalProvider.successMessage(response.message);

                        setLoading(false);
                        setFormSubmitted(false);
                        props.history.push('/dashboard');
                    } else {
                        setLoading(false);
                        setFormSubmitted(false);
                        GlobalProvider.errorMessage(response.message);
                    }

                }).catch(error => {
                    setLoading(false);
                    setFormSubmitted(false);
                    GlobalProvider.errorMessage(error);
                });

        } else {
            setErrors(errors);
            setLoading(false);
            setFormSubmitted(false);
        }
    }

    return (
        <>
            <SideMenuData />

            <main className="admin-main">

                <Header />

                <section className="admin-content ">
                    <div className="bg-dark bg-dots m-b-30">
                        <div className="container">
                            <div className="row p-b-60 p-t-60">
                                <div className="col-lg-8 mx-auto text-white p-b-30">
                                    <h3>Update Profile Form </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="pull-up">
                        <div className="container">
                            <div className="row ">
                                <div className="col-lg-8 mx-auto  mt-2">
                                    <div className="card py-3 m-b-30">
                                        <div className="card-body">

                                            <form className="needs-validation" onSubmit={update}>

                                                <h3 className="">Update Information</h3>

                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label for="inputEmail6">Name*</label>
                                                        <input type="text" className="form-control" name="name" defaultValue={formData.name} onKeyUp={handleChange} />
                                                        {errors.name && <span className="error">{errors.name}</span>}
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label for="inputEmail4">Email</label>
                                                        <input type="email" className="form-control" name="email" defaultValue={formData.email} onKeyUp={handleChange} />
                                                        {errors.email && <span className="error">{errors.email}</span>}
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label for="asd">Username</label>
                                                        <input type="text" className="form-control" name="username" defaultValue={formData.username} readOnly />
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label for="inputPassword4">Mobile</label>
                                                        <input type="phone" className="form-control" name="mobile" defaultValue={formData.mobile} onKeyUp={handleChange} />
                                                        {errors.mobile && <span className="error">{errors.mobile}</span>}
                                                    </div>
                                                </div>
                                                {/* <div className="form-group">
                                                    <label for="inputAddress">Address</label>
                                                    <input type="text" className="form-control" name="address" defaultValue={formData.address} onKeyUp={handleChange} />
                                                    {errors.address && <span className="error">{errors.address}</span>}
                                                </div> */}

                                                <button type="submit" className="btn btn-primary btn-cta" disabled={loading}>{loading ? 'Waiting...' : 'Submit'}</button>
                                                    &nbsp;&nbsp;
                                                <Link to={"/dashboard"} className="btn btn-dark btn-cta">Cancel</Link>

                                            </form>

                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </section>
                </section>
            </main>

        </>
    );
}

export default EditProfile;