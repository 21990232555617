import React, { Component, useState } from "react";
import './login.css';
import * as CustomValidators from '../../providers/shared/validator';
import * as GlobalProvider from '../../providers/globals/globals';
import * as ApisService from "../../providers/apis/apis";

function Login(props) {

  const[formData, setFormData] = useState({});
  const[errors, setErrors] = useState({});
  const[loading, setLoading] = useState(false);
  const[formSubmitted, setFormSubmitted] = useState(false);

  const handleInputChange = (event) => {

    const target = event.target;
    const value = target.value;
    const name = target.name;

    formData[name] = value;

    setFormData(formData);
  }

  const validateLoginForm = (e) => {

    let errors = {};

    if (CustomValidators.isEmpty(formData.username)) {
      errors.username = "Username/Email/Mobile can't be blank";
    }
    else if (CustomValidators.isContainWhiteSpace(formData.username)) {
      errors.username = "Username/Email/Mobile should not contain white spaces";
    }
    else if (!CustomValidators.isLength(formData.username, { gte: 5, lte: 32, trim: true })) {
      errors.username = "Username/Email/Mobile's length must between 5 to 32";
    }
    // else if (!CustomValidators.isEmail(formData.username)) {
    //   errors.username = "Please enter a valid email";
    // }

    if (CustomValidators.isEmpty(formData.password)) {
      errors.password = "Password can't be blank";
    }
    else if (CustomValidators.isContainWhiteSpace(formData.password)) {
      errors.password = "Password should not contain white spaces";
    }
    else if (!CustomValidators.isLength(formData.password, { gte: 6, lte: 16, trim: true })) {
      errors.password = "Password's length must between 6 to 16";
    }

    if (CustomValidators.isEmpty(errors)) {
      return null;
    } else {
      return errors;
    }
  }

  const login = (e) => {

    e.preventDefault();

    setErrors({});
    setLoading(true);
    setFormSubmitted(true);

    // console.log('formData::::', formData)

    let errors = validateLoginForm();

    if (!errors) {

      ApisService.login(formData)
        .then(response => {

          // console.log('response::::', response)

          if(response.status) {
            // store user information
            GlobalProvider.setToken(response.token);
            GlobalProvider.setUser(response.data);
            props.history.push('/dashboard');
          } else {
            setErrors({message: response.message});
            setLoading(false);
            setFormSubmitted(false);
          }
        }).catch(error => {
          setErrors({message: error});
          setLoading(false);
          setFormSubmitted(false);
        });

    } else {
      setErrors(errors);
      setLoading(false);
      setFormSubmitted(false);
    }
  }

  return (

    <main className="bg-pattern">
      <div className="container">
        <div className="row m-h-100 ">
          <div className="col-md-8 col-lg-4 m-auto">
            <div className="card shadow-lg ">
              <div className="card-body ">
                <div className=" padding-box-2 ">
                  <div className="p-b-20 text-center">
                    <p>
                      <img src="assets/logos/logo.png" width="100" alt="" />
                    </p>
                  </div>

                  <form className="needs-validation" onSubmit={login}>
                    <div className="form-row">
                      <div className="form-group floating-label col-md-12">
                        <label>Username / Email / Mobile</label>
                        <input name="username" type="text" placeholder="Username / Email / Mobile" className="form-control" onChange={handleInputChange} />
                        {errors.username && <span className="error">{errors.username}</span>}
                      </div>
                      <div className="form-group floating-label col-md-12">
                        <label>Password</label>
                        <input name="password" type="password" placeholder="Password" className="form-control" onChange={handleInputChange} />
                        {errors.password && <span className="error">{errors.password}</span>}
                      </div>
                    </div>

                    {errors.message && <><span className="error-response">{errors.message}</span></>}
                    <button type="submit" className="btn btn-primary btn-block btn-lg" disabled={loading}>{loading ? 'Loading...' : 'Login'}</button>

                  </form>

                  <p className="text-right p-t-10">
                    {/* <a href="#!" className="text-underline">Forgot Password?</a> */}
                  </p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Login;