import React, { useEffect, useState } from 'react';
import { NavLink, Link } from "react-router-dom";
import * as GlobalProvider from '../../providers/globals/globals';
import Header from '../../components/elements/Header';
import Pagenation from '../../components/elements/Pagenation';
import RecordNotFound from '../../components/elements/RecordNotFound';
import * as ApisService from "../../providers/apis/apis";
import { Roller } from "react-awesome-spinners";
import SideMenu from '../../components/elements/SideMenu';
import * as CustomValidators from '../../providers/shared/validator';

function StoriesArchive(props) {

    const [currentUser, setCurrentUser] = useState({});
    const [loading, setLoading] = useState(false);
    const [entries, setEntries] = useState([]);

    useEffect(() => {

        loadData();
    }, []);

    const loadData = () => {

        setLoading(true);

        ApisService.getArchiveStories()
            .then(response => {

                if (response.status) {
                    setEntries(response.data);
                    setLoading(false);
                } else {
                    setLoading(false);
                    GlobalProvider.errorMessage(response.message);
                }

                GlobalProvider.loadDataTable();

            }).catch(error => {
                setLoading(false);
                GlobalProvider.errorMessage(error);
            });
    }

    const restore = (id, index) => {

        GlobalProvider.confirmBox("Are you Sure? You want to restore this record.", (isTrue) => {
            if (isTrue) {
                ApisService.restoreStory(id)
                    .then(response => {

                        if (response.status) {

                            let entriesNew = entries.filter((item) => {
                                if (item.id != id) {
                                    return item;
                                }
                            });

                            setEntries(entriesNew);

                            GlobalProvider.successMessage(response.message);

                        } else {
                            GlobalProvider.errorMessage(response.message);
                        }

                    }).catch(error => {
                        GlobalProvider.errorMessage(error.message);
                    });
            }
        })
    }

    return (
        <>
            <SideMenu />

            <main className="admin-main">

                <Header />

                <section className="admin-content">
                    <div className="bg-dark">
                        <div className="container  m-b-30">
                            <div className="row">
                                <div className="col-12 text-white p-t-40 p-b-90">
                                    <h4 className="">Success Stories</h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    {loading && <div className="center"><Roller /></div>}

                    {!loading && entries.length == 0 && <RecordNotFound />}

                    {entries.length > 0 &&

                        <div className="container  pull-up">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">

                                        <div className="card-body">
                                            <div className="table-responsive p-t-10">
                                                <table id="listingTable" className="table " style={{ width: "100%" }}>
                                                    <thead>
                                                        <tr>
                                                            <th>Customer Name</th>
                                                            <th>Title</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {entries.map((item, index) =>
                                                            <tr key={item.id} id={'RecordID_' + item.id}>
                                                                <td>{item.customer_name}</td>
                                                                <td>{item.title}</td>
                                                                <td>
                                                                    <button className="btn btn-sm m-b-15 ml-2 mr-2 btn-rounded-circle btn-success" title="Restore" onClick={() => restore(item.id, index)}><i className="mdi mdi-restore"></i></button>
                                                                </td>
                                                            </tr>
                                                        )}

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                </section>

            </main>
        </>
    );

}

export default StoriesArchive;