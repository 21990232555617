import React, { useEffect, useState } from 'react';
import { NavLink, Link } from "react-router-dom";
import * as GlobalProvider from '../../providers/globals/globals';
import Header from '../../components/elements/Header';
import Pagenation from '../../components/elements/Pagenation';
import * as ApisService from "../../providers/apis/apis";
import { Roller } from "react-awesome-spinners";
import SideMenu from '../../components/elements/SideMenu';
import { Button } from 'react-bootstrap';

function UserView(props) {

    const [id] = useState(props.match.params.id);
    const [currentUser, setCurrentUser] = useState({});
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState({});

    useEffect(() => {

        loadData(id);
    }, []);

    const loadData = (id) => {

        setLoading(true);

        ApisService.getUserDetail(id)
            .then(response => {

                if (response.status) {
                    setLoading(false);
                    setDetail(response.data);
                } else {
                    GlobalProvider.errorMessage(response.message);
                }

            }).catch(error => {
                GlobalProvider.errorMessage(error);
            });
    }

    return (
        <>
            <SideMenu />

            <main className="admin-main">

                <Header />

                <section className="admin-content ">
                    <div className="bg-dark bg-dots m-b-30">
                        <div className="container">
                            <div className="row p-b-60 p-t-60">
                                <div className="col-lg-8 mx-auto text-white p-b-30">
                                    <h3>Detail Form </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="pull-up">
                        <div className="container">
                            <div className="row ">
                                <div className="col-lg-8 mx-auto  mt-2">
                                    <div className="card py-3 m-b-30">
                                        <div className="card-body">
                                            <form>

                                                {/* <div className="form-row center">
                                                    <div className="form-group col-md-12">
                                                        <img src={detail.image} alt={detail.name} width="200" style={{ borderRadius: '50%' }} />
                                                    </div>
                                                </div> */}

                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label>First Name</label>
                                                        <input type="text" value={detail.name} className="form-control" readOnly />
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label>Last Name</label>
                                                        <input type="text" value={detail.last_name} className="form-control" readOnly />
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label>Email</label>
                                                        <input type="text" value={detail.email} className="form-control" readOnly />
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label>DOB</label>
                                                        <input type="text" value={detail.dob} className="form-control" readOnly />
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label>Mobile</label>
                                                        <input type="text" value={detail.mobile} className="form-control" readOnly />
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label for="inputEmail4">Status</label>
                                                        <select className="form-control" name="status" value={detail.status} disabled>
                                                            <option value={1}>Approved</option>
                                                            <option value={0}>Un-Approved</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label>Created At</label>
                                                        <input type="text" value={detail.created_at} className="form-control" readOnly />
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label>Updated At</label>
                                                        <input type="text" value={detail.updated_at} className="form-control" readOnly />
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <br />

                                                        <a target="__blank" href={detail.image} download>
                                                            <Button size="small" to={detail.image}>
                                                                <i className="icon-placeholder mdi mdi-download-outline "></i>
                                                            &nbsp;&nbsp;&nbsp;
                                                                Download Document
                                                            </Button>
                                                        </a>
                                                        <br /><br />
                                                    </div>
                                                </div>

                                                <Link to={"/users-edit/" + detail.id} className="btn btn-success btn-cta">Edit</Link>
                                                    &nbsp;&nbsp;
                                                <Link to={"/users"} className="btn btn-dark btn-cta">Back</Link>

                                            </form>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </section>
                </section>
            </main>

        </>
    );

}

export default UserView;