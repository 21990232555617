import React, { useState, useEffect } from 'react';
import { NavLink, Link } from "react-router-dom";
import * as SiteSettings from '../../providers/settings/settings';
import * as Permissions from '../../providers/settings/permissions';
import * as GlobalProvider from '../../providers/globals/globals';
import * as GlobalConfig from '../../providers/globals/config';

function SideMenuData(props) {

    const [currentUser, setCurrentUser] = useState({});
    const [activePath, setActivePath] = useState('');

    useEffect(() => {
        setCurrentUser(GlobalProvider.getUser());

        let pathname = window.location.pathname;
        SiteSettings.definedMenus.forEach((item) => {
            if (pathname.indexOf(item) !== -1) {
                setActivePath(item);
            }
        })
    }, []);

    return (

        <aside className="admin-sidebar">
            <div className="admin-sidebar-brand">
                {/* <!-- begin sidebar branding--> */}
                <img className="admin-brand-logo" src="/assets/logos/logo-new.png" width="90" alt={GlobalConfig.appName} />
                {/* <span className="admin-brand-content font-secondary"><a href="/dashboard">{GlobalConfig.appName}</a></span> */}
                {/* <!-- end sidebar branding--> */}
                <div className="ml-auto">
                    {/* <!-- sidebar pin--> */}
                    <a href="#" className="admin-pin-sidebar btn-ghost btn btn-rounded-circle"></a>
                    {/* <!-- sidebar close for mobile device--> */}
                    <a href="#" className="admin-close-sidebar"></a>
                </div>
            </div>
            <div className="admin-sidebar-wrapper js-scrollbar">
                <ul className="menu">

                    <>
                        <li className={activePath == '/dashboard' ? 'menu-item active opened' : 'menu-item'}>
                            <NavLink to="/dashboard" className=" menu-link">
                                <span className="menu-label">
                                    <span className="menu-name">Dashboard</span>
                                </span>
                                <span className="menu-icon">
                                    <i className="icon-placeholder mdi mdi-shape-outline "></i>
                                </span>
                            </NavLink>
                        </li>

                        <li className={activePath == '/users' ? 'menu-item active opened' : 'menu-item'}>
                            <NavLink to="#" className="open-dropdown menu-link">
                                <span className="menu-label">
                                    <span className="menu-name">Users
                                            <span className="menu-arrow"></span>
                                    </span>
                                </span>
                                <span className="menu-icon">
                                    <i className="icon-placeholder mdi mdi-account"></i>
                                </span>
                            </NavLink>
                            <ul className="sub-menu" style={activePath == '/users' ? { display: 'block' } : { display: 'none' }}>
                                <li className="menu-item">
                                    <NavLink to="/users" className=" menu-link">
                                        <span className="menu-label">
                                            <span className="menu-name">List</span>
                                        </span>
                                        <span className="menu-icon">
                                            <i className="icon-placeholder mdi mdi-menu"></i>
                                        </span>
                                    </NavLink>
                                </li>
                                <li className="menu-item">
                                    <NavLink to="/users-archive" className=" menu-link">
                                        <span className="menu-label">
                                            <span className="menu-name">Archived</span>
                                        </span>
                                        <span className="menu-icon">
                                            <i className="icon-placeholder mdi mdi-delete-sweep"></i>
                                        </span>
                                    </NavLink>
                                </li>
                            </ul>
                        </li>

                        <li className={activePath == '/stories' ? 'menu-item active opened' : 'menu-item'}>
                            <NavLink to="#" className="open-dropdown menu-link">
                                <span className="menu-label">
                                    <span className="menu-name">Success Stories
                                            <span className="menu-arrow"></span>
                                    </span>
                                </span>
                                <span className="menu-icon">
                                    <i className="icon-placeholder mdi mdi-history"></i>
                                </span>
                            </NavLink>
                            <ul className="sub-menu" style={activePath == '/stories' ? { display: 'block' } : { display: 'none' }}>
                                <li className="menu-item">
                                    <NavLink to="/stories" className=" menu-link">
                                        <span className="menu-label">
                                            <span className="menu-name">List</span>
                                        </span>
                                        <span className="menu-icon">
                                            <i className="icon-placeholder mdi mdi-menu"></i>
                                        </span>
                                    </NavLink>
                                </li>
                                <li className="menu-item">
                                    <NavLink to="/stories-add" className=" menu-link">
                                        <span className="menu-label">
                                            <span className="menu-name">Add</span>
                                        </span>
                                        <span className="menu-icon">
                                            <i className="icon-placeholder mdi mdi-plus "></i>
                                        </span>
                                    </NavLink>
                                </li>
                                <li className="menu-item">
                                    <NavLink to="/stories-archive" className=" menu-link">
                                        <span className="menu-label">
                                            <span className="menu-name">Archived</span>
                                        </span>
                                        <span className="menu-icon">
                                            <i className="icon-placeholder mdi mdi-delete-sweep"></i>
                                        </span>
                                    </NavLink>
                                </li>
                            </ul>
                        </li>

                        <li className={activePath == '/bodylines' ? 'menu-item active opened' : 'menu-item'}>
                            <NavLink to="#" className="open-dropdown menu-link">
                                <span className="menu-label">
                                    <span className="menu-name">Body Lines
                                            <span className="menu-arrow"></span>
                                    </span>
                                </span>
                                <span className="menu-icon">
                                    <i className="icon-placeholder mdi mdi-snowman"></i>
                                </span>
                            </NavLink>
                            <ul className="sub-menu" style={activePath == '/bodylines' ? { display: 'block' } : { display: 'none' }}>
                                <li className="menu-item">
                                    <NavLink to="/bodylines" className=" menu-link">
                                        <span className="menu-label">
                                            <span className="menu-name">List</span>
                                        </span>
                                        <span className="menu-icon">
                                            <i className="icon-placeholder mdi mdi-menu"></i>
                                        </span>
                                    </NavLink>
                                </li>
                                <li className="menu-item">
                                    <NavLink to="/bodylines-add" className=" menu-link">
                                        <span className="menu-label">
                                            <span className="menu-name">Add</span>
                                        </span>
                                        <span className="menu-icon">
                                            <i className="icon-placeholder mdi mdi-plus "></i>
                                        </span>
                                    </NavLink>
                                </li>
                                <li className="menu-item">
                                    <NavLink to="/bodylines-archive" className=" menu-link">
                                        <span className="menu-label">
                                            <span className="menu-name">Archived</span>
                                        </span>
                                        <span className="menu-icon">
                                            <i className="icon-placeholder mdi mdi-delete-sweep"></i>
                                        </span>
                                    </NavLink>
                                </li>
                            </ul>
                        </li>

                        <li className={activePath == '/programs' ? 'menu-item active opened' : 'menu-item'}>
                            <NavLink to="#" className="open-dropdown menu-link">
                                <span className="menu-label">
                                    <span className="menu-name">Choose Programs
                                            <span className="menu-arrow"></span>
                                    </span>
                                </span>
                                <span className="menu-icon">
                                    <i className="icon-placeholder mdi mdi-calendar-edit"></i>
                                </span>
                            </NavLink>
                            <ul className="sub-menu" style={activePath == '/programs' ? { display: 'block' } : { display: 'none' }}>
                                <li className="menu-item">
                                    <NavLink to="/programs" className=" menu-link">
                                        <span className="menu-label">
                                            <span className="menu-name">List</span>
                                        </span>
                                        <span className="menu-icon">
                                            <i className="icon-placeholder mdi mdi-menu"></i>
                                        </span>
                                    </NavLink>
                                </li>
                                <li className="menu-item">
                                    <NavLink to="/programs-add" className=" menu-link">
                                        <span className="menu-label">
                                            <span className="menu-name">Add</span>
                                        </span>
                                        <span className="menu-icon">
                                            <i className="icon-placeholder mdi mdi-plus "></i>
                                        </span>
                                    </NavLink>
                                </li>
                                <li className="menu-item">
                                    <NavLink to="/programs-archive" className=" menu-link">
                                        <span className="menu-label">
                                            <span className="menu-name">Archived</span>
                                        </span>
                                        <span className="menu-icon">
                                            <i className="icon-placeholder mdi mdi-delete-sweep"></i>
                                        </span>
                                    </NavLink>
                                </li>
                            </ul>
                        </li>

                        <li className={activePath == '/faqs' ? 'menu-item active opened' : 'menu-item'}>
                            <NavLink to="#" className="open-dropdown menu-link">
                                <span className="menu-label">
                                    <span className="menu-name">Faqs
                                            <span className="menu-arrow"></span>
                                    </span>
                                </span>
                                <span className="menu-icon">
                                    <i className="icon-placeholder mdi mdi-help"></i>
                                </span>
                            </NavLink>
                            <ul className="sub-menu" style={activePath == '/faqs' ? { display: 'block' } : { display: 'none' }}>
                                <li className="menu-item">
                                    <NavLink to="/faqs" className=" menu-link">
                                        <span className="menu-label">
                                            <span className="menu-name">List</span>
                                        </span>
                                        <span className="menu-icon">
                                            <i className="icon-placeholder mdi mdi-menu"></i>
                                        </span>
                                    </NavLink>
                                </li>
                                <li className="menu-item">
                                    <NavLink to="/faqs-add" className=" menu-link">
                                        <span className="menu-label">
                                            <span className="menu-name">Add</span>
                                        </span>
                                        <span className="menu-icon">
                                            <i className="icon-placeholder mdi mdi-plus "></i>
                                        </span>
                                    </NavLink>
                                </li>
                                <li className="menu-item">
                                    <NavLink to="/faqs-archive" className=" menu-link">
                                        <span className="menu-label">
                                            <span className="menu-name">Archived</span>
                                        </span>
                                        <span className="menu-icon">
                                            <i className="icon-placeholder mdi mdi-delete-sweep"></i>
                                        </span>
                                    </NavLink>
                                </li>
                            </ul>
                        </li>

                        <li className={activePath == '/pages' ? 'menu-item active opened' : 'menu-item'}>
                            <NavLink to="#" className="open-dropdown menu-link">
                                <span className="menu-label">
                                    <span className="menu-name">Pages
                                            <span className="menu-arrow"></span>
                                    </span>
                                </span>
                                <span className="menu-icon">
                                    <i className="icon-placeholder mdi mdi-file"></i>
                                </span>
                            </NavLink>
                            <ul className="sub-menu" style={activePath == '/pages' ? { display: 'block' } : { display: 'none' }}>
                                <li className="menu-item">
                                    <NavLink to="/pages" className=" menu-link">
                                        <span className="menu-label">
                                            <span className="menu-name">List</span>
                                        </span>
                                        <span className="menu-icon">
                                            <i className="icon-placeholder mdi mdi-menu"></i>
                                        </span>
                                    </NavLink>
                                </li>
                                <li className="menu-item">
                                    <NavLink to="/pages-add" className=" menu-link">
                                        <span className="menu-label">
                                            <span className="menu-name">Add</span>
                                        </span>
                                        <span className="menu-icon">
                                            <i className="icon-placeholder mdi mdi-plus "></i>
                                        </span>
                                    </NavLink>
                                </li>
                                <li className="menu-item">
                                    <NavLink to="/pages-archive" className=" menu-link">
                                        <span className="menu-label">
                                            <span className="menu-name">Archived</span>
                                        </span>
                                        <span className="menu-icon">
                                            <i className="icon-placeholder mdi mdi-delete-sweep"></i>
                                        </span>
                                    </NavLink>
                                </li>
                            </ul>
                        </li>

                        <li className={activePath == '/settings' ? 'menu-item active opened' : 'menu-item'}>
                            <NavLink to="#" className="open-dropdown menu-link">
                                <span className="menu-label">
                                    <span className="menu-name">Settings
                                            <span className="menu-arrow"></span>
                                    </span>
                                </span>
                                <span className="menu-icon">
                                    <i className="icon-placeholder mdi mdi-settings"></i>
                                </span>
                            </NavLink>
                            <ul className="sub-menu" style={activePath == '/home-settings' ? { display: 'block' } : { display: 'none' }}>
                                <li className="menu-item">
                                    <NavLink to="/home-settings" className=" menu-link">
                                        <span className="menu-label">
                                            <span className="menu-name">Home Page</span>
                                        </span>
                                        <span className="menu-icon">
                                            <i className="icon-placeholder mdi mdi-home"></i>
                                        </span>
                                    </NavLink>
                                </li>
                            </ul>
                        </li>

                        <li className={activePath == '/contacts' ? 'menu-item active opened' : 'menu-item'}>
                            <NavLink to="/contacts" className=" menu-link">
                                <span className="menu-label">
                                    <span className="menu-name">Contact us</span>
                                </span>
                                <span className="menu-icon">
                                    <i className="icon-placeholder mdi mdi-email "></i>
                                </span>
                            </NavLink>
                        </li>

                        <li className={activePath == '/newsletters' ? 'menu-item active opened' : 'menu-item'}>
                            <NavLink to="/newsletters" className=" menu-link">
                                <span className="menu-label">
                                    <span className="menu-name">Newsletters</span>
                                </span>
                                <span className="menu-icon">
                                    <i className="icon-placeholder mdi mdi-bell "></i>
                                </span>
                            </NavLink>
                        </li>

                    </>

                </ul>
            </div>

        </aside>

    );
}

export default SideMenuData;