import React, { useEffect, useState } from 'react';
import { NavLink, Link } from "react-router-dom";
import * as GlobalProvider from '../../providers/globals/globals';
import Header from '../../components/elements/Header';
import Pagenation from '../../components/elements/Pagenation';
import * as ApisService from "../../providers/apis/apis";
import { Roller } from "react-awesome-spinners";
import SideMenu from '../../components/elements/SideMenu';

function PageView(props) {

    const [id] = useState(props.match.params.id);
    const [currentUser, setCurrentUser] = useState({});
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState({});

    useEffect(() => {

        loadData(id);
    }, []);

    const loadData = (id) => {

        setLoading(true);

        ApisService.getPageDetail(id)
            .then(response => {

                if (response.status) {
                    setLoading(false);
                    setDetail(response.data);
                } else {
                    GlobalProvider.errorMessage(response.message);
                }

            }).catch(error => {
                GlobalProvider.errorMessage(error);
            });
    }

    return (
        <>
            <SideMenu />

            <main className="admin-main">

                <Header />

                <section className="admin-content ">
                    <div className="bg-dark bg-dots m-b-30">
                        <div className="container">
                            <div className="row p-b-60 p-t-60">
                                <div className="col-lg-12 mx-auto text-white p-b-30">
                                    <h3>Detail Form </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="pull-up">
                        <div className="container">
                            <div className="row ">
                                <div className="col-lg-12 mx-auto  mt-2">
                                    <div className="card py-3 m-b-30">
                                        <div className="card-body">
                                            <form>

                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label>Title</label>
                                                        <input type="text" value={detail.title} className="form-control" readOnly />
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label>Page Key</label>
                                                        <input type="text" value={detail.page_key} className="form-control" readOnly />
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="form-row">
                                                    <div className="form-group col-md-12">
                                                        <label><strong>Meta Key</strong></label>
                                                        <div>{detail.meta_key}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="form-row">
                                                    <div className="form-group col-md-12">
                                                        <label><strong>Meta Description</strong></label>
                                                        <div>{detail.meta_description}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="form-row">
                                                    <div className="form-group col-md-12">
                                                        <label><strong>Description</strong></label>
                                                        <div dangerouslySetInnerHTML={{ __html: detail.description }} />
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label for="inputEmail4">Status</label>
                                                        <select className="form-control" name="status" value={detail.status} disabled>
                                                            <option value={1}>Active</option>
                                                            <option value={0}>In-Active</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label>Created At</label>
                                                        <input type="text" value={detail.created_at} className="form-control" readOnly />
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label>Updated At</label>
                                                        <input type="text" value={detail.updated_at} className="form-control" readOnly />
                                                    </div>
                                                </div>

                                                <Link to={"/pages-edit/" + detail.id} className="btn btn-success btn-cta">Edit</Link>
                                                    &nbsp;&nbsp;
                                                <Link to={"/pages"} className="btn btn-dark btn-cta">Back</Link>

                                            </form>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </section>
                </section>
            </main>

        </>
    );

}

export default PageView;