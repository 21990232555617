import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Login from '../pages/login/Login';
import Dashboard from '../pages/dashboard/Dashboard';

// users
import Users from '../pages/users/Users';
import UserView from '../pages/users/UserView';
import UserEdit from '../pages/users/UserEdit';
import UsersArchive from '../pages/users/UsersArchive';

// success stories
import Stories from '../pages/stories/Stories';
import StoryAdd from '../pages/stories/StoryAdd';
import StoryView from '../pages/stories/StoryView';
import StoryEdit from '../pages/stories/StoryEdit';
import StoriesArchive from '../pages/stories/StoriesArchive';

// body lines
import Bodylines from '../pages/bodylines/Bodylines';
import BodylineAdd from '../pages/bodylines/BodylineAdd';
import BodylineView from '../pages/bodylines/BodylineView';
import BodylineEdit from '../pages/bodylines/BodylineEdit';
import BodylineArchive from '../pages/bodylines/BodylinesArchive';

// choose-programs
import Programs from '../pages/programs/Programs';
import ProgramAdd from '../pages/programs/ProgramAdd';
import ProgramView from '../pages/programs/ProgramView';
import ProgramEdit from '../pages/programs/ProgramEdit';
import ProgramArchive from '../pages/programs/ProgramsArchive';

// faqs
import Faqs from '../pages/faqs/Faqs';
import FaqAdd from '../pages/faqs/FaqAdd';
import FaqView from '../pages/faqs/FaqView';
import FaqEdit from '../pages/faqs/FaqEdit';
import FaqArchive from '../pages/faqs/FaqsArchive';

// pages
import Pages from '../pages/pages/Pages';
import PageAdd from '../pages/pages/PageAdd';
import PageView from '../pages/pages/PageView';
import PageEdit from '../pages/pages/PageEdit';
import PagesArchive from '../pages/pages/PagesArchive';

// contacts
import Contacts from '../pages/contacts/Contacts';
import ContactView from '../pages/contacts/ContactView';

// settings
import ChangePassword from '../pages/settings/ChangePassword';
import EditProfile from '../pages/settings/EditProfile';
import HomeSettings from '../pages/settings/HomeSettings';

// newsletters
import Newsletters from '../pages/newsletters/Newsletters';

import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

// handle the all routes
function RoutesList() {

  return (
    <BrowserRouter>
      <Switch>

        <Route exact path="/" component={Login} />

        <PublicRoute path="/login" component={Login} />
        <PrivateRoute path="/dashboard" component={Dashboard} />

        {/* users */}
        <PrivateRoute path="/users" component={Users} />
        <PrivateRoute path="/users-view/:id" component={UserView} />
        <PrivateRoute path="/users-edit/:id" component={UserEdit} />
        <PrivateRoute path="/users-archive" component={UsersArchive} />

        {/* success stories */}
        <PrivateRoute path="/stories" component={Stories} />
        <PrivateRoute path="/stories-view/:id" component={StoryView} />
        <PrivateRoute path="/stories-add" component={StoryAdd} />
        <PrivateRoute path="/stories-edit/:id" component={StoryEdit} />
        <PrivateRoute path="/stories-archive" component={StoriesArchive} />

        {/* body lines */}
        <PrivateRoute path="/bodylines" component={Bodylines} />
        <PrivateRoute path="/bodylines-view/:id" component={BodylineView} />
        <PrivateRoute path="/bodylines-add" component={BodylineAdd} />
        <PrivateRoute path="/bodylines-edit/:id" component={BodylineEdit} />
        <PrivateRoute path="/bodylines-archive" component={BodylineArchive} />

        {/* choose programs */}
        <PrivateRoute path="/programs" component={Programs} />
        <PrivateRoute path="/programs-view/:id" component={ProgramView} />
        <PrivateRoute path="/programs-add" component={ProgramAdd} />
        <PrivateRoute path="/programs-edit/:id" component={ProgramEdit} />
        <PrivateRoute path="/programs-archive" component={ProgramArchive} />

        {/* faqs */}
        <PrivateRoute path="/faqs" component={Faqs} />
        <PrivateRoute path="/faqs-view/:id" component={FaqView} />
        <PrivateRoute path="/faqs-add" component={FaqAdd} />
        <PrivateRoute path="/faqs-edit/:id" component={FaqEdit} />
        <PrivateRoute path="/faqs-archive" component={FaqArchive} />

        {/* pages */}
        <PrivateRoute path="/pages" component={Pages} />
        <PrivateRoute path="/pages-view/:id" component={PageView} />
        <PrivateRoute path="/pages-add" component={PageAdd} />
        <PrivateRoute path="/pages-edit/:id" component={PageEdit} />
        <PrivateRoute path="/pages-archive" component={PagesArchive} />

        {/* contacts */}
        <PrivateRoute path="/contacts" component={Contacts} />
        <PrivateRoute path="/contacts-view/:id" component={ContactView} />

        {/* newsletters */}
        <PrivateRoute path="/newsletters" component={Newsletters} />

        {/* settings */}
        <PrivateRoute path="/change-password" component={ChangePassword} />
        <PrivateRoute path="/edit-profile" component={EditProfile} />
        <PrivateRoute path="/home-settings" component={HomeSettings} />

      </Switch>
    </BrowserRouter>
  )
}

export default RoutesList;