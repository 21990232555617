import React, { useState } from 'react';
import { Link } from "react-router-dom";
import * as GlobalProvider from '../../providers/globals/globals';
import Header from '../../components/elements/Header';
import * as ApisService from "../../providers/apis/apis";
import SideMenuData from '../../components/elements/SideMenu';
import * as CustomValidators from '../../providers/shared/validator';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

function PageAdd(props) {

    const [currentUser, setCurrentUser] = useState({});
    const [formData, setFormData] = useState({ status: 1 });
    const [description, setDescription] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);

    const handleChange = (event) => {

        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;

        formData[name] = value;

        setFormData(formData);
    }

    const updateDescription = () => {

        formData.description = description;
        setFormData(formData);
    }

    const validateLoginForm = (e) => {

        let errors = {};

        if (CustomValidators.isEmpty(formData.title)) {
            errors.title = "Title can't be blank";
        }

        if (CustomValidators.isEmpty(formData.page_key)) {
            errors.page_key = "Page Key can't be blank";
        }

        if (CustomValidators.isEmpty(formData.description)) {
            errors.description = "Description can't be blank";
        }

        if (CustomValidators.isEmpty(formData.meta_key)) {
            errors.meta_key = "Meta Key can't be blank";
        }

        if (CustomValidators.isEmpty(formData.meta_description)) {
            errors.meta_description = "Meta Description can't be blank";
        }

        if (CustomValidators.isEmpty(errors)) {
            return null;
        } else {
            return errors;
        }
    }

    const addEdit = (e) => {

        e.preventDefault();

        updateDescription(); // update ck-editor text into form-data

        setErrors({});
        setLoading(true);
        setFormSubmitted(true);

        let errors = validateLoginForm();

        if (!errors) {

            ApisService.createPage(formData)
                .then(response => {

                    if (response.status) {

                        GlobalProvider.successMessage(response.message);

                        setLoading(false);
                        setFormSubmitted(false);
                        props.history.push('/pages');
                    } else {
                        setLoading(false);
                        setFormSubmitted(false);
                        GlobalProvider.errorMessage(response.message);
                    }

                }).catch(error => {
                    setLoading(false);
                    setFormSubmitted(false);
                    GlobalProvider.errorMessage(error);
                });

        } else {
            setErrors(errors);
            setLoading(false);
            setFormSubmitted(false);
        }
    }

    return (
        <>
            <SideMenuData />

            <main className="admin-main">

                <Header />

                <section className="admin-content ">
                    <div className="bg-dark bg-dots m-b-30">
                        <div className="container">
                            <div className="row p-b-60 p-t-60">
                                <div className="col-lg-12 mx-auto text-white p-b-30">
                                    <h3>Add Form </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="pull-up">
                        <div className="container">
                            <div className="row ">
                                <div className="col-lg-12 mx-auto  mt-2">
                                    <div className="card py-3 m-b-30">
                                        <div className="card-body">

                                            <form className="needs-validation" onSubmit={addEdit} method="true">

                                                <h3 className="">Add Information</h3>

                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="inputEmail4">Title*</label>
                                                        <input type="text" className="form-control" name="title" defaultValue={formData.title} onKeyUp={handleChange} />
                                                        {errors.title && <span className="error">{errors.title}</span>}
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="inputEmail6">Page Key*</label>
                                                        <input type="text" className="form-control" name="page_key" defaultValue={formData.page_key} onKeyUp={handleChange} />
                                                        {errors.page_key && <span className="error">{errors.page_key}</span>}
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-md-12">
                                                        <label>Meta Key*</label>
                                                        <textarea rows="2" name="meta_key" defaultValue={formData.meta_key} className="form-control" onKeyUp={handleChange} />
                                                        {errors.meta_key && <span className="error">{errors.meta_key}</span>}
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-md-12">
                                                        <label>Meta Description*</label>
                                                        <textarea rows="3" name="meta_description" defaultValue={formData.meta_description} className="form-control" onKeyUp={handleChange} />
                                                        {errors.meta_description && <span className="error">{errors.meta_description}</span>}
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-md-12">
                                                        <label>Description*</label>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={formData.description}
                                                            onReady={editor => {
                                                                const data = editor.getData();
                                                                // You can store the "editor" and use when it is needed.
                                                                // console.log('Editor is ready to use!', editor);
                                                                setDescription(data);
                                                            }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                setDescription(data);
                                                            }}
                                                            onBlur={(event, editor) => {
                                                                // console.log('Blur.', editor);
                                                            }}
                                                            onFocus={(event, editor) => {
                                                                // console.log('Focus.', editor);
                                                            }}
                                                        />
                                                        {errors.description && <span className="error">{errors.description}</span>}
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="inputEmail4">Status</label>
                                                        <select className="form-control" name="status" onChange={handleChange} >
                                                            <option value={1}>Active</option>
                                                            <option value={0}>In-Active</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <button type="submit" className="btn btn-primary btn-cta" disabled={loading}>{loading ? 'Waiting...' : 'Submit'}</button>
                                                    &nbsp;&nbsp;
                                                <Link to={"/pages"} className="btn btn-dark btn-cta">Cancel</Link>

                                            </form>

                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </section>
                </section>
            </main>

        </>
    );
}

export default PageAdd;