import React, { useEffect, useState } from 'react';
import Header from '../../components/elements/Header';
import SideMenuData from '../../components/elements/SideMenu';
import * as GlobalProvider from '../../providers/globals/globals';
import * as ApisService from "../../providers/apis/apis";
import { Roller } from "react-awesome-spinners";
import { Link } from 'react-router-dom';

function Dashboard(props) {

  const [loading, setLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState([]);

  useEffect(() => {

    loadData();

    // setTimeout(() => {
    //   GlobalProvider.loadDashboardCharts();
    // }, 100);
  }, []);

  const loadData = () => {

    setLoading(true);

    ApisService.getDashboard()
      .then(response => {

        if (response.status) {
          setDashboardData(response.data);
          setLoading(false);
        } else {
          setLoading(false);
          GlobalProvider.errorMessage(response.message);
        }

        GlobalProvider.loadDataTable();

      }).catch(error => {
        setLoading(false);
        GlobalProvider.errorMessage(error);
      });
  }

  return (
    <>
      <SideMenuData />

      <main className="admin-main">

        <Header />

        {loading && <div className="center"><Roller /></div>}

        {!loading && <section className="admin-content">
          <div className="container py-5">

            <div className="row">

              <div className="col-md-6 col-lg-4">
                <div className="card m-b-30 bg-dark">
                  <div className="card-body text-white">
                    <Link to={'users'}>
                      <div className="row p-t-30 p-r-30 p-b-30 p-l-30">
                        <div className="my-auto col-md-7">
                          <h1 className="m-0">{dashboardData.users}</h1>
                          <p className="m-0"> Users </p>
                        </div>
                        <div className="my-auto text-md-right col-md-5">
                          <a href="#" className="btn btn-rounded-circle btn-lg btn-success">
                            <i className="mdi mdi-account"></i>
                          </a>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="card m-b-30 bg-dark">
                  <div className="card-body text-white">
                    <Link to={'contacts'}>
                      <div className="row p-t-30 p-r-30 p-b-30 p-l-30">
                        <div className="my-auto col-md-7">
                          <h1 className="m-0">{dashboardData.contact_us}</h1>
                          <p className="m-0"> Contact Us </p>
                        </div>
                        <div className="my-auto text-md-right col-md-5">
                          <a href="#" className="btn btn-rounded-circle btn-lg btn-success">
                            <i className="mdi mdi-email"></i>
                          </a>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="card m-b-30 bg-dark">
                  <div className="card-body text-white">
                    <Link to={'newsletters'}>
                      <div className="row p-t-30 p-r-30 p-b-30 p-l-30">
                        <div className="my-auto col-md-7">
                          <h1 className="m-0">{dashboardData.newsletters}</h1>
                          <p className="m-0"> Newsletters </p>
                        </div>
                        <div className="my-auto text-md-right col-md-5">
                          <a href="#" className="btn btn-rounded-circle btn-lg btn-success">
                            <i className="mdi mdi-bell"></i>
                          </a>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </section>}

      </main>

    </>
  );
}

export default Dashboard;